



















































































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {mapGetters} from "vuex";
//@ts-ignore
import mondaySdk from "monday-sdk-js";
import {CreateThirdPartyObjective} from "@/graphql/objective";
import Template from "@/components/templates/Template.vue";

const monday = mondaySdk();
@Component({
  components: {Template},
  computed: {
    ...mapGetters({
      mondayToken: 'getMondayToken',
    })
  }
})
export default class Monday extends Vue {
  @Prop({required: true})
  boards!: Array<any>;

  @Prop({required: true})
  providerId!: String;

  mondayClientId: any = process.env.VUE_APP_MONDAY_CLIENT_ID;
  mondayToken!: any;
  submitLoading: boolean = false;
  allMondayBoards: Array<any> = [];
  allTasks: Array<any> = [];
  selectedMondayTasks: Array<any> = [];
  selectBoard: any | null = null;
  width: number = 500;
  height: number = 590;
  left: number = (screen.width - this.width) / 2;
  top: number = (screen.height - this.height) / 4;
  personalAccessToken: string | null = null;
  invalidPersonalAccessToken: boolean = false;
  errorMessage: string | null = null;

  get isLoggedIn(){
    this.getBoards();
    return this.mondayToken;
  }
  get isDisabled() {

    if(this.selectedMondayTasks.length === 0){
      return true;
    }
    return false;
  }

  get board() {
    return this.$store.state.board;
  }

  get isConnectButtonDisabled(){
    if(!this.personalAccessToken){
      return true;
    }
    return false;
  }


  authenticate(){
    if(this.mondayToken){
      this.getBoards();
    }
    else {
      monday.setToken(this.personalAccessToken!);
      monday.api(`query {
                        me {
                          is_guest
                          created_at
                          name
                          id
                        }
      }`).then(() => {
        this.invalidPersonalAccessToken = true;
        this.$store.commit('set_monday_token', this.personalAccessToken);
      }).catch(() => {
        this.invalidPersonalAccessToken = true;
        this.errorMessage = "Please check the api token provided"
      })
    }
  }

  removeAllSelection() {
    this.selectedMondayTasks = [];
  }

  selectAll() {
    this.selectedMondayTasks = [...this.allTasks];
  }

  logout(){
    this.$store.commit('set_monday_token', null);
    this.invalidPersonalAccessToken = false;
    this.errorMessage = null;
    this.personalAccessToken = null;
    this.selectedMondayTasks = [];
    this.allTasks = [];

  }

  getBoards() {
    monday.setToken(this.mondayToken);
    monday.api(`query {
                        boards {
                          id
                          name
                          items {
                            id
                            name

                          }
                        }
    }`).then((res: any) => {
      this.allMondayBoards = res.data.boards;
    })
  }

  getTasks(board: any){
    this.allTasks = board.items;
    //this.selectedMondayTasks = [...board.items];
  }

  createCards(){
    this.submitLoading = true;
    let mondayTasks = this.selectedMondayTasks.map((card: any)=>{
      return {third_party_provider_id:this.$props.providerId,provider_card_id: card.id,name: card.name,description: null, start_date: null, due_date: null, board: this.board.id};
    });

    this.$apollo.mutate({
      mutation: CreateThirdPartyObjective,
      variables:{
        data: mondayTasks
      }
    })
      .then((_data) => {
        this.$buefy.toast.open({
          message: 'Objective added !!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        //@ts-ignore
        this.$parent.close();
      })
    ;

  }

  mounted(){
    //@ts-ignore
    monday.setClientId(this.mondayClientId);
  }
}
