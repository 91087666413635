

















































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Template from "@/components/workspace/Template.vue";
import {CreateThirdPartyObjective} from "@/graphql/objective";
declare global {
  interface Window {
    Trello:any;
  }
}
const TrelloApi = window.Trello;
@Component({
  components: {Template}
})
export default class Trello extends Vue {
  @Prop({required: true})
  boards!: Array<any>;

  @Prop({required: true})
  providerId!: String;

  submitLoading: boolean = false;

  isLoggedIn: boolean  = false;
  memberBoards: Array<any> = [];
  boardCards: Array<any> = [];
  selectedTrelloCards: Array<any> = [];
  selectedTrelloBoard: string | null = null;
  selectBoard: any | null = null;

  get board() {
    return this.$store.state.board;
  }

  get isDisabled() {

    if(this.selectedTrelloCards.length === 0){
      return true;
    }
    return false;
  }


  authenticate(){
    TrelloApi.authorize({
      name: "Pembio",
      type: "popup",
      success: () => {
        this.isLoggedIn = !this.isLoggedIn
        TrelloApi.get("member/me/boards", (result: any) => {
          this.memberBoards = result;
        })
      }
    })
  }

  getCards(board: any){
    this.selectedTrelloBoard = board.name;
    this.selectedTrelloCards = [];
    TrelloApi.get("boards/"+board.id+"/cards", (results: any) => {
      this.boardCards = results;
      //this.selectedTrelloCards = [...results];
    })
  }


  createCards(){
    this.submitLoading = true;
    let trelloCards = this.selectedTrelloCards.map((card: any)=>{
      return {third_party_provider_id:this.$props.providerId,provider_card_id: card.id,name: card.name,description: card.desc, start_date: card.start, due_date: card.due, board: this.board.id};
    });

    this.$apollo.mutate({
      mutation: CreateThirdPartyObjective,
      variables:{
        data: trelloCards
      }
    })
      .then((_data) => {
        this.$buefy.toast.open({
          message: 'Items added!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        //@ts-ignore
        this.$parent.close();
      })
    ;

  }

  removeAllSelection() {
    this.selectedTrelloCards = [];
  }

  selectAll() {
    this.selectedTrelloCards = [...this.boardCards];
  }

  logout(){
    TrelloApi.deauthorize();
    this.isLoggedIn = !this.isLoggedIn
  }

  mounted(){
    this.authenticate();
  }
}
