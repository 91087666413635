













































































import { Component, Vue } from 'vue-property-decorator';
import {BoardQuery} from "@/graphql/board";
import Trello from "@/components/board/thirdPartyApps/trello/Trello.vue";
import {ThirdPartyQuery} from "@/graphql/ThirdPartyProvider";
import Asana from "@/components/board/thirdPartyApps/asana/Asana.vue";
import GoogleCalendar from "@/components/board/thirdPartyApps/google-calendar/GoogleCalendar.vue";
import Monday from "@/components/board/thirdPartyApps/monday/Monday.vue";
import Todoist from "@/components/board/thirdPartyApps/todoist/Todoist.vue";
import Wrike from "@/components/board/thirdPartyApps/wrike/Wrike.vue";
import gql from 'graphql-tag';

@Component({
  components: {
    Trello,
    Asana,
    GoogleCalendar,
    Monday,
    Todoist
  },
  apollo: {
    getThirdPartyProviders: {
      query: ThirdPartyQuery,
      result(result){
        this.thirdPartyProviders = result.data.getThirdPartyProviders;
      }
    }
  }
})
export default class BoardApps extends Vue {
  searchInput: string | null = '';
  thirdPartyProviders: Array<any> = [];
  helpExpanded: boolean = false;

  get board() {
    return this.$store.state.board;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  openThirdPartyModal(name: string, id: string) {
    let lowerName = name.toLowerCase();
    switch (lowerName) {
      case 'trello':
        this.$buefy.modal.open({
          component: Trello,
          width: '480px',
          props: {boards: this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'asana':
        this.$buefy.modal.open({
          component: Asana,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'google calendar':
        this.$buefy.modal.open({
          component: GoogleCalendar,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'monday.com':
        this.$buefy.modal.open({
          component: Monday,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'todoist':
        this.$buefy.modal.open({
          component: Todoist,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
      case 'wrike':
        this.$buefy.modal.open({
          component: Wrike,
          width: '480px',
          props: {boards:this.workspace.boards, providerId: id},
          // @ts-ignore
          parent: this,
        });
        break;
    }
  }

  slackConnect() {
    let url = '/slackconnect/callback?redir=true';
    let options = `toolbar=0,scrollbars=1,status=0,resizable=1,location=0,menuBar=0,width=600,height=700,left=200,top=200`;
    return window.open(url, 'Slack Connect', options);
  }

  slackDisonnect() {
    this.$apollo.mutate({
      mutation: gql`
                    mutation ConnectSlackRemove($board_id: ID!) {
                        connectSlackRemove(board_id: $board_id) {
                            id
                            pid
                        }
                    }`,
      variables: {
        board_id: this.$store.state.board.id,
      },
      refetchQueries: [{ query: BoardQuery, variables: { id: this.$store.state.board.id } }],
    })
      .then(() => {
       
      })
      .catch(() => {
        
      })
      .finally(() => {
        
      });
  }
}
