









































































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {CreateThirdPartyObjective} from "@/graphql/objective";

@Component({
  components: {},
  computed: {
    ...mapGetters({
      googleToken: 'getGoogleToken',
      googleTokenExpiresIn: 'getGoogleTokenExpireIn'
    })
  }
})
export default class GoogleCalendar extends Vue {
  @Prop({required: true})
  boards!: Array<any>;

  @Prop({required: true})
  providerId!: String;

  googleClientId: any = process.env.VUE_APP_GOOGLE_CLIENT_ID;
  redirectUrl: string = window.location.origin+'/authcallback/google-calendar';
  googleToken!: any;
  googleTokenExpiresIn!: any;
  submitLoading: boolean = false;
  allCalendarsList: Array<any> = [];
  allEvents: Array<any> = [];
  selectedGoogleEvents: Array<any> = [];
  selectBoard: any | null = null;
  width: number = 500;
  height: number = 590;
  left: number = (screen.width - this.width) / 2;
  top: number = (screen.height - this.height) / 4;

  get isLoggedIn(){
    this.getCalendars();
    return this.googleToken;
  }
  get isDisabled() {

    if(this.selectedGoogleEvents.length === 0){
      return true;
    }
    return false;
  }

  get board() {
    return this.$store.state.board;
  }

  authenticate(){
    if (new Date() > new Date(this.googleTokenExpiresIn)) {
      this.$store.commit('set_google_token', null);
      this.$store.commit('set_google_token_expired', null);
    }

    if(this.googleToken){
      this.getCalendars();
    }
    else{
      window.open('https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&client_id=' + this.googleClientId + '&redirect_uri='+ this.redirectUrl + '&scope=openid%20email%20profile%20https://www.googleapis.com/auth/calendar', 'window', 'width='+this.width+',height='+this.height+',top='+this.top+',left='+this.left);
    }
  }

  async getCalendars(){
    const headers = { "Content-Type": "application/json","Authorization": "Bearer "+this.googleToken };
    const response = await fetch("https://www.googleapis.com/calendar/v3/users/me/calendarList?key="+process.env.VUE_APP_GOOGLE_API_KEY,{headers});
    const data = await response.json();
    this.allCalendarsList = data.items;
  }

  async getEvents(calendar: any){
    const headers = { "Content-Type": "application/json","Authorization": "Bearer "+this.googleToken };
    const response = await fetch("https://www.googleapis.com/calendar/v3/calendars/"+encodeURIComponent(calendar.id)+"/events?key="+process.env.VUE_APP_GOOGLE_API_KEY,{headers});
    const data = await response.json();
    this.allEvents = data.items.filter((event: any)=>{
      if('dateTime' in event.start){
        return new Date(event.start.dateTime) >= new Date();
      }
      if('date' in event.start){
        return new Date(event.start.date) >= new Date();
      }

    });
    //this.selectedGoogleEvents = [...this.allEvents];

  }

  removeAllSelection() {
    this.selectedGoogleEvents = [];
  }

  selectAll() {
    this.selectedGoogleEvents = [...this.allEvents];
  }

  logout(){
    this.$store.commit('set_google_token', null);
    this.$store.commit('set_google_token_expired', null);
  }

  createCards(){
    this.submitLoading = true;
    let googleEvents = this.selectedGoogleEvents.map((card: any)=>{
      return {third_party_provider_id:this.$props.providerId,provider_card_id: card.id,name: card.summary,description: null, start_date: ('dateTime' in card.start)? new Date(card.start.dateTime): new Date(card.start.date), due_date: ('dateTime' in card.end)? new Date(card.end.dateTime): new Date(card.end.date), board: this.board.id};
    });

    this.$apollo.mutate({
      mutation: CreateThirdPartyObjective,
      variables:{
        data: googleEvents
      }
    })
      .then((_data) => {
        this.$buefy.toast.open({
          message: 'Objective added !!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        //@ts-ignore
        this.$parent.close();
      })
    ;

  }

  mounted(){
    this.authenticate();
  }
}
