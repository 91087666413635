










































































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {mapGetters} from "vuex";
import {CreateThirdPartyObjective} from "@/graphql/objective";

@Component({
  components: {},
  computed: {
    ...mapGetters({
      wrikeToken: 'getWrikeToken',
      wrikeTokenExpiresIn: 'getWrikeTokenExpireIn'
    })
  }
})
export default class Wrike extends Vue {
  @Prop({required: true})
  boards!: Array<any>;

  @Prop({required: true})
  providerId!: String;

  wrikeToken!: any;
  wrikeTokenExpiresIn!: any;
  wrikeClientId: any = process.env.VUE_APP_WRIKE_CLIENT_ID;
  redirectUrl: string = window.location.origin+'/authcallback/wrike';
  submitLoading: boolean = false;
  allProjectsOrFolders: Array<any> = [];
  allTasks: Array<any> = [];
  selectedTasks: Array<any> = [];
  selectBoard: any | null = null;
  width: number = 500;
  height: number = 590;
  left: number = (screen.width - this.width) / 2;
  top: number = (screen.height - this.height) / 4;

  get isLoggedIn(){
    this.getProjects();
    return this.wrikeToken;
  }
  get isDisabled() {

    if(this.selectedTasks.length === 0){
      return true;
    }
    return false;
  }

  get board() {
    return this.$store.state.board;
  }

  authenticate(){
    if (new Date() > new Date(this.wrikeTokenExpiresIn)) {
      this.$store.commit('set_wrike_token', null);
      this.$store.commit('set_wrike_token_expired', null);
    }
    if(this.wrikeToken){
      this.getProjects();
    }
    else{
      window.open('https://login.wrike.com/oauth2/authorize/v4?&client_id=' + this.wrikeClientId + '&redirect_uri='+ this.redirectUrl + '&response_type=code', 'window', 'width='+this.width+',height='+this.height+',top='+this.top+',left='+this.left);
    }
  }

  removeAllSelection() {
    this.selectedTasks = [];
  }

  selectAll() {
    this.selectedTasks = [...this.allTasks];
  }

  logout(){
    this.$store.commit('set_wrike_token', null);
    this.$store.commit('set_wrike_token_expired', null);
  }

  async getProjects(){
    const headers = { "Content-Type": "application/json","Authorization": "Bearer "+this.wrikeToken };
    const response = await fetch("https://www.wrike.com/api/v4/folders",{headers});
    const data = await response.json();
    this.allProjectsOrFolders = data.data.filter((project: any) => {
      return project.scope === 'WsFolder'
    });
  }

  async getTasks(project: any){
    const headers = { "Content-Type": "application/json","Authorization": "Bearer "+this.wrikeToken };
    const response = await fetch("https://www.wrike.com/api/v4/folders/"+project.id+"/tasks",{headers});
    const data = await response.json();
    this.allTasks = data.data;
    //this.selectedTasks = [...this.allTasks];
  }

  createCards(){
    this.submitLoading = true;
    let wrikeTasks = this.selectedTasks.map((card: any)=>{
      return {third_party_provider_id:this.$props.providerId,provider_card_id: card.id,name: card.title,description: null, start_date: (card.dates.type == 'Planned')? new Date(card.dates.start): null, due_date: (card.dates.type == 'Planned')? new Date(card.dates.due): null, board: this.board.id};
    });

    this.$apollo.mutate({
      mutation: CreateThirdPartyObjective,
      variables:{
        data: wrikeTasks
      }
    })
      .then((_data) => {
       
        this.$buefy.toast.open({
          message: 'Objective added !!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        //@ts-ignore
        this.$parent.close();
      })
    ;

  }

  mounted(){
    this.authenticate()
  }
}
