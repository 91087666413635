























































































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {CreateThirdPartyObjective} from "@/graphql/objective";
import {mapGetters} from "vuex";

const AsanaApi = require('asana');
const client = AsanaApi.Client.create({
  clientId: process.env.VUE_APP_ASANA_CLIENT_ID,
  clientSecret: process.env.VUE_APP_ASANA_SECRET_KEY,
  redirectUri: 'http://localhost:8080'
});

@Component({
  components: {},
  computed: {
    ...mapGetters({
      asanaToken: 'getAsanaToken',
      asanaTokenExpiresIn: 'getAsanaTokenExpireIn'
    })
  }
})

export default class Asana extends Vue {
  @Prop({required: true})
  boards!: Array<any>;

  @Prop({required: true})
  providerId!: String;

  submitLoading: boolean = false;
  asanaToken!: any;
  asanaTokenExpiresIn!: any;
  allWorkspaces: Array<any> = [];
  allProjects: Array<any> = [];
  allTasks: Array<any> = [];
  selectedAsanaTasks: Array<any> = [];
  selectBoard: any | null = null;
  width: number = 450;
  height: number = 490;
  left: number = (screen.width - this.width) / 2;
  top: number = (screen.height - this.height) / 4;

  get isLoggedIn(){
    if(this.asanaToken != null){
      client.useOauth({
        credentials: this.asanaToken
      });
      this.getWorkspaces();
    }
    return this.asanaToken;
  }
  get isDisabled() {

    if(this.selectedAsanaTasks.length === 0){
      return true;
    }
    return false;
  }

  get board() {
    return this.$store.state.board;
  }

  authenticate() {
    if (new Date() > new Date(this.asanaTokenExpiresIn)) {
      this.$store.commit('set_asana_token', null);
      this.$store.commit('set_asana_token_expired', null);
    }

    if (this.asanaToken) {
      client.useOauth({
        credentials: this.asanaToken
      });
      this.getWorkspaces();
    } else {
      window.open('https://app.asana.com/-/oauth_authorize?client_id=' + client.app.clientId + '&redirect_uri=' + window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/authcallback/asana' + '&response_type=code' + '&state=thisIsARandomString' + '&scope=default', 'window', 'width=' + this.width + ',height=' + this.height + ',top=' + this.top + ',left=' + this.left);
    }
  }

  removeAllSelection() {
    this.selectedAsanaTasks = [];
  }

  selectAll() {
    this.selectedAsanaTasks = [...this.allTasks];
  }

  logout(){
    this.$store.commit('set_asana_token', null);
    this.$store.commit('set_asana_token_expired', null);
  }

  createCards(){
    this.submitLoading = true;
    let asanaCards = this.selectedAsanaTasks.map((card: any)=>{
      return {third_party_provider_id:this.$props.providerId,provider_card_id: card.gid,name: card.name,description: null, start_date: null, due_date: null, board: this.board.id};
    });

    this.$apollo.mutate({
      mutation: CreateThirdPartyObjective,
      variables:{
        data: asanaCards
      }
    })
      .then((_data) => {
        this.$buefy.toast.open({
          message: 'Objective added !!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        //@ts-ignore
        this.$parent.close();
      })
    ;

  }

  async getWorkspaces(){
    await client.users.me().then((res: any)=>{
      this.allWorkspaces = res.workspaces;
    });
  }

  async getProjects(workspace: any){
    await client.projects.getProjects({workspace: workspace.gid, limit: 100}).then((res: any)=>{
      this.allProjects = res.data;
    });
  }

  async getTasks(project: any){
    await client.tasks.getTasks({project: project.gid, limit: 100}).then((res: any)=>{
      this.allTasks = res.data;
      //this.selectedAsanaTasks = [...this.allTasks];
    });
  }

  mounted(){
    this.authenticate();
  }

}
