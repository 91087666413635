











































































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import uniqueString from 'unique-string';
import {mapGetters} from "vuex";
import {CreateThirdPartyObjective} from "@/graphql/objective";


@Component({
  components: {},
  computed: {
    ...mapGetters({
      todoistToken: 'getTodoistToken',
    })
  }
})
export default class Todoist extends Vue {
  @Prop({required: true})
  boards!: Array<any>;

  @Prop({required: true})
  providerId!: String;

  todoistClientId: any = process.env.VUE_APP_TODOIST_CLIENT_ID;
  todoistToken!: any;
  submitLoading: boolean = false;
  allProjectsList: Array<any> = [];
  allTasks: Array<any> = [];
  selectedTasks: Array<any> = [];
  selectBoard: any | null = null;
  width: number = 500;
  height: number = 590;
  left: number = (screen.width - this.width) / 2;
  top: number = (screen.height - this.height) / 4;

  get isLoggedIn(){
    this.getProjects();
    return this.todoistToken;
  }
  get isDisabled() {

    if(this.selectedTasks.length === 0){
      return true;
    }
    return false;
  }

  get board() {
    return this.$store.state.board;
  }

  authenticate(){
    if(this.todoistToken){
      this.getProjects();
    }
    else{
      window.open('https://todoist.com/oauth/authorize?client_id=' + this.todoistClientId + '&scope=data:read&state='+ uniqueString(), 'window', 'width='+this.width+',height='+this.height+',top='+this.top+',left='+this.left);
    }
  }

  removeAllSelection() {
    this.selectedTasks = [];
  }

  selectAll() {
    this.selectedTasks = [...this.allTasks];
  }

  logout(){
    this.$store.commit('set_todoist_token', null);
  }

  async getProjects(){
    const headers = { "Content-Type": "application/json","Authorization": "Bearer "+this.todoistToken };
    const response = await fetch("https://api.todoist.com/rest/v1/projects",{headers});
    const data = await response.json();
    this.allProjectsList = data;
  }

  async getTasks(project: any){
    const headers = { "Content-Type": "application/json","Authorization": "Bearer "+this.todoistToken };
    const response = await fetch("https://api.todoist.com/rest/v1/tasks?project_id="+project.id,{headers});
    const data = await response.json();
    this.allTasks = data
    //this.selectedTasks = [...this.allTasks];
  }
  createCards(){
    this.submitLoading = true;
    let todoistTasks = this.selectedTasks.map((card: any)=>{
      return {third_party_provider_id:this.$props.providerId,provider_card_id: card.id,name: card.content,description: card.description, start_date: null, due_date: null, board: this.board.id};
    });

    this.$apollo.mutate({
      mutation: CreateThirdPartyObjective,
      variables:{
        data: todoistTasks
      }
    })
      .then((_data) => {
        this.$buefy.toast.open({
          message: 'Objective added !!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        //@ts-ignore
        this.$parent.close();
      })
    ;

  }

  mounted(){
    this.authenticate();
  }
}
